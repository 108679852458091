<script>
import Swal from "sweetalert2";
import Layout from "@/layouts/main.vue";
import appConfig from "@/app.config";
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import _ from "lodash";
import Multiselect from "@vueform/multiselect";
import exportExcel from '@/helpers/exportExcel.js'

export default {
  page: {
    title: "SMS Batch List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalTitle: "",
      fileList:{},
      checkAll: false,
      title: "SMS Batches",
      items: [
        {
          text: "SMS Batches",
          href: "/",
        },
        {
          text: "SMS Batch List",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      sliderCustomzie: [1000, 3000],
      userData: [],
      pages: [],
      page: 1,
      perPage: 10,
      value: ["Watches", "Headset"],
      UserDetailsData: {},
      formData: {
        name: "",
        type:"",
        region_id:"",
        region_ids: null,
      },
      table_options: {
        current_page: 1,
        page_size: 10,
        search_text: "",
        sort: { column: "", direction: "" },
      },
      meta: {},
      selectedUserIds:[],
      userRoles: [],

      regionList: [],
      tagvalue: null,
      tagoption: [{
        value: 'Lead',
        label: 'Lead'
      },
        {
          value: 'Partner',
          label: 'Partner'
        },
        {
          value: 'Exiting',
          label: 'Exiting'
        },
        {
          value: 'Long-tern',
          label: 'Long-tern'
        },
      ],

      regionOption:[]
    };
  },
  computed: {},
  watch: {},
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    //EXPORT USERS
    exportUsers() {
      this.$store.dispatch('users/exportUsers')
          .then( res => {
            exportExcel(res, 'user-list.xlsx')
          })
    },

    // CHILD CHECKBOXES
    handleChildCheckBoxChange(payload) {
      if(this.selectedUserIds.includes(payload.data.id)) {
        let index = this.selectedUserIds.indexOf(payload.data.id)
        this.selectedUserIds.splice(index,1)
      } else {
        this.selectedUserIds.push(payload.data.id)
      }
      var total = 0;
      var checkchild = document.querySelectorAll('.form-check-all input[type="checkbox"]');
      checkchild.forEach(function (checkbox) {
        checkbox.onclick = function () {
          total = 0
          checkchild.forEach(function (box) {
            if(box.checked === true) {
              total++;
            }
          })
          if (checkbox.checked === true) {
            checkbox.closest("tr").classList.add("table-active");
            document.getElementById('selection-element').style.display = 'block'
            document.getElementById('select-content').innerHTML = total;
          } else {
            if (total <= 0) {
              document.getElementById('selection-element').style.display = 'none'
            }
            checkbox.closest("tr").classList.remove("table-active");
            document.getElementById('select-content').innerHTML = total;
          }
        }
      })
    },
    // All checkbox
    handleCheckAllUserChange() {
      let checkboxes = document.querySelectorAll('.form-check-all input[type="checkbox"]');
      if(this.checkAll){
        this.selectedUserIds = []
        this.userData.forEach( data => this.selectedUserIds.push(data.id))
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = true;
          document.getElementById('selection-element').style.display = 'block'
          document.getElementById('select-content').innerHTML = checkboxes.length;
          checkbox.closest("tr").classList.add("table-active");
        });
      } else {
        this.selectedUserIds = []
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
          document.getElementById('selection-element').style.display = 'none'
          checkbox.closest("tr").classList.remove("table-active");
        });
      }
    },
    //open edit User
    openEditClient(data) {
      this.modalTitle = "Edit Data";
      this.formData = {
        ...data,
      };

    },
    //add and edit User data
    openUserModal() {
      Object.keys(this.formData).forEach( key => this.formData[key] = "")
      this.modalTitle = 'Add Data'
    },
    addSmsGroup() {
      let userFormData = new FormData()
      userFormData.append('message', this.formData.name)
      userFormData.append('group_id[]', this.tagvalue)






      if (this.modalTitle === "Add Data") {
        this.fileList = {}
        Object.keys(this.formData).forEach( key => this.formData[key] = "")
        this.$store
            .dispatch("sms/sendSmsBatch", userFormData)
            .then(() => {
              this.getUsers();
              Swal.fire("Success!", "User added successfully.", "success");
            })
            .catch((err) => {
              console.log(err);
            });
      } else {
        userFormData.append("_method", "put")
        userFormData.append('status_id', this.formData.status_id)
        this.$store
            .dispatch("users/editUser",{
              id: this.formData.id,
              data: userFormData
            })
            .then(() => {
              this.getUsers()
              Swal.fire(
                  "Success!",
                  "User updated successfully.",
                  "success"
              );
            })
            .catch((err) => {
              console.log(err);
            });
      }
      document.getElementById("closemodal").click();
      document.getElementById("addform").reset();
    },
    //get all Users
    getUsers() {
      this.$store
          .dispatch("sms/getSmsBatches", this.table_options)
          .then((res) => {
            console.log(res)
            this.userData = res.data;
            this.meta.total = res.total;
            this.meta.from = res.from;
            this.meta.last_page = res.last_page;
          })
          .catch((err) => console.log(err));
    },
    //delete client data
    deleteUser(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store
              .dispatch(`users/deleteUser`, event.id)
              .then(() => {
                this.getUsers()
                Swal.fire(
                    "Deleted!",
                    "User deleted successfully.",
                    "success"
                );
              })
              .catch((er) => {
                console.log(er);
              });
        }
      });
    },
    deleteMultiple() {
      if (typeof this.selectedUserIds !== "undefined" && this.selectedUserIds.length > 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete this?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            this.$store
                .dispatch(`users/deleteBulkUsers`,this.selectedUserIds)
                .then(() => {
                  this.getUsers()
                  document.getElementById("checkAll").checked = false;
                  var itemss = document.getElementsByName("chk_child");
                  itemss.forEach(function (ele) {
                    if (ele.checked === true) {
                      ele.checked = false;
                      ele.closest("tr").classList.remove("table-active");
                      document.getElementById("selection-element").style.display =
                          "none";
                    }
                  });
                  Swal.fire(
                      "Deleted!",
                      "User deleted successfully.",
                      "success"
                  );
                })
                .catch((er) => {
                  console.log(er);
                });
          }
        });
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    debounceInput: _.debounce(function (e) {
      this.table_options.search_text = e.target.value;
      this.$store
          .dispatch("sms/getSmsGroups", this.table_options)
          .then((response) => {
            this.userData = response.data;
            this.meta.total = response.total;
            this.meta.from = response.from;
            this.meta.last_page = response.last_page;
          });
    }, 500),

    handlePageChange(e) {
      this.table_options.current_page = e;
      this.$store
          .dispatch("sms/getSmsGroups", this.table_options)
          .then((response) => {
            this.userData = response.data;
            this.meta.total = response.total;
            this.meta.from = response.from;
            this.meta.last_page = response.last_page;
          });
    },
  },
  mounted() {
    this.getUsers();



   //  this.$store
   //      .dispatch("region/getRegionList")
   //      .then((res) => {
   // console.log(res.data)
   //        this.regionList = res.data
   //      })
   //      .catch((err) => console.log(err));


    this.$store.dispatch('sms/getSmsGroupList')
        .then( res => this.regionOption = res.map( category => {
          console.log(res)

          return {
            label: category.name,
            value: category.id
          }
        }))

  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect
  },
};
</script>

<template>
  <Layout>
    <!-- end table responsive -->
    <PageHeader :title="title" :items="items" />
    <!-- ADD CLIENT MODAL -->
    <div
        class="modal fade"
        id="showModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
            ></button>
          </div>
          <form action="#" id="addform" @submit.prevent="addSmsGroup">
            <div class="modal-body">
              <div class="mb-3" id="modal-id">
                <label for="name" class="form-label">Name</label>
<!--                <input-->
<!--                    type="text"-->
<!--                    v-model="formData.name"-->
<!--                    id="name"-->
<!--                    class="form-control"-->
<!--                    placeholder="Enter Name"-->
<!--                    required-->
<!--                />-->
                <textarea  type="text"
                           v-model="formData.name"
                           id="name"
                           class="form-control"
                           placeholder="Enter Name"
                           required></textarea>
              </div>






              <div class="mb-3">
                <label for="" class="form-label">Regions</label>
                <Multiselect v-model="tagvalue" mode="tags" :close-on-select="true" :searchable="true" name="tagvalue[]"
                             :create-option="false" :options="regionOption" />
              </div>


            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                    id="closemodal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success" id="add-btn">
                  {{ modalTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END ADD User MODAL -->

    <!-- User DETAILS -->
    <div
        class="modal fade"
        id="showClientDetailModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">Details</h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                <tr>
                  <th class="ps-0" scope="row">Name :</th>
                  <td class="text-muted">{{ UserDetailsData.name}}</td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row">Type :</th>
                  <td class="text-muted">{{ UserDetailsData.type }}</td>
                </tr>


                <tr>
                  <th class="ps-0" scope="row">Status:</th>
                  <td class="text-muted">
                      <span class="badge text-uppercase" :class="[
                            UserDetailsData.status_id === 1 ? 'badge-soft-success': 'badge-soft-danger',
                        ]">{{ UserDetailsData.status_id === 1 ? 'Active' : 'Inactive' }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="closemodal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END CLIENT DETAILS -->
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <button
                        type="button"
                        @click="openUserModal"
                        class="btn btn-success add-btn"
                        data-bs-toggle="modal"
                        id="create-btn"
                        data-bs-target="#showModal"
                    >
                      <i class="ri-add-line align-bottom me-1"></i> Add Data
                    </button>
                    <button
                        type="button"
                        @click="exportUsers"
                        class="btn btn-primary m-2 add-btn">
                      Export
                    </button>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-sm-end">
                    <div class="search-box ms-2">
                      <input
                          type="text"
                          @input="debounceInput"
                          class="form-control"
                          placeholder="Search ..."
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                      class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                      role="tablist"
                  >
                    <li class="nav-item">
                      <a
                          class="nav-link active fw-semibold"
                          data-bs-toggle="tab"
                          href="#Usernav-all"
                          role="tab"
                      >
                        All

                        <span
                            class="badge badge-soft-danger align-middle rounded-pill ms-1"
                        >{{ meta.total }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Select
                      <div
                          id="select-content"
                          class="text-body fw-semibold px-1"
                      ></div>
                      Result
                      <button
                          type="button"
                          class="btn btn-link link-danger p-0 ms-3 shadow-none"
                          data-bs-toggle="modal"
                          data-bs-target="#removeItemModal"
                          @click="deleteMultiple"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div
                    class="tab-pane active"
                    id="Usernav-all"
                    role="tabpanel"
                >
                  <div id="table-User-list-all " class="table-card  gridjs-border-none table-responsive ">
                    <table class="table align-middle table-nowrap mb-0" id="customerTable">
                      <thead class="table-light">
                      <tr>
                        <th scope="col" style="width: 50px">
                          <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkAll"
                                style="cursor:pointer"
                                v-model="checkAll"
                                @change="handleCheckAllUserChange"
                            />
                          </div>
                        </th>
                        <th>Name</th>


                        <th>Type</th>

                        <th>Status</th>
                        <th scope="col">Action</th>
                      </tr>
                      </thead>
                      <tbody class="list form-check-all">
                      <tr
                          class="gridjs-tr"
                          v-for="(data, index) of userData"
                          :key="index"
                      >
                        <td
                            data-column-id="UserListAllCheckbox"
                            class="gridjs-td"
                        >
                          <input
                              type="checkbox"
                              name="chk_child"
                              @change="handleChildCheckBoxChange({data:data, index:index})"
                              style="cursor:pointer"
                              class="form-check-input form_chk_child"
                          />
                        </td>
                        <td class="id" hidden>
                          <a href="" >{{ data.id }}</a>
                        </td>
                        <td data-column-id="name" class="gridjs-td">


<!--                          <router-link-->

<!--                              :to="{ path : '/sms/group-contacts', params : { id : data.id, data: data } }"-->

<!--                              class="nav-link" data-key="t-crm">-->
<!--                            {{ data.name }}-->
<!--                          </router-link>-->
<!--                          -->

                          <router-link

                              :to="{name : 'sms-group-contacts', params : { id : data.id, data: data.name } }"

                            class="nav-link" data-key="t-crm">
                            {{ data.message}}
                          </router-link>


                        </td>

                        <td data-column-id="type" class="gridjs-td">
                          {{ data.type }}
                        </td>


                        <td data-column-id="status_id" class="gridjs-td">
                            <span class="badge text-uppercase"
                                  :class="[data.status_id === 1 ? 'badge-soft-success': 'badge-soft-danger']">
                            {{ data.status_id === 1 ? 'Active' : 'Inactive' }}
                          </span>
                        </td>
                        <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button
                                    class="btn btn-soft-secondary btn-sm dropdown"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                        data-bs-toggle="modal"
                                        id="details-btn"
                                        data-bs-target="#showClientDetailModal"
                                        @click="UserDetailsData = { ...data }"
                                        class="dropdown-item"
                                    >
                                      <i
                                          class="ri-eye-fill align-bottom me-2 text-muted"
                                      ></i>
                                      View
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                        @click="openEditClient(data)"
                                        class="dropdown-item"
                                        data-bs-toggle="modal"
                                        id="edit-btn"
                                        data-bs-target="#showModal"
                                    >
                                      <i
                                          class="ri-pencil-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Edit
                                    </button>
                                  </li>

                                  <li class="dropdown-divider"></li>
                                  <li>
                                    <a
                                        class="dropdown-item"
                                        @click="deleteUser(data)"
                                        style="cursor:pointer"
                                    >
                                      <i
                                          class="ri-delete-bin-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Delete</a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div
                        @click="handlePageChange(table_options.current_page)"
                        class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                    >

                      <b-pagination
                          v-model="table_options.current_page"
                          :total-rows="meta.total"
                          :per-page="table_options.page_size"
                          prev-text="Prev"
                          next-text="Next"
                          prev-class="prev"
                          next-class="next"
                          class=" pagination-wrap hstack gap-2"
                      >
                        <template #prev-text>
                          <div class="page-item shadow-sm pagination-prev disabled">
                            Previous
                          </div>
                        </template>
                        <template #next-text>
                          <div class="page-item shadow-sm  pagination-next">Next</div>
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-published" role="tabpanel">
                  <div
                      id="table-User-list-published"
                      class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                          class="avatar-xl"
                          colors="primary:#121331,secondary:#08a88a"
                          :options="defaultOptions"
                          :height="75"
                          :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Sorry! No Result Found</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
